// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-menu-entry[data-v-20d8ad9e] {
  --app-menu-entry-font-size: 12px;
  width: var(--header-height);
  height: var(--header-height);
  position: relative;
}
.app-menu-entry__link[data-v-20d8ad9e] {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-background-plain-text);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  margin: 2px;
}
.app-menu-entry__label[data-v-20d8ad9e] {
  opacity: 0;
  position: absolute;
  font-size: var(--app-menu-entry-font-size);
  color: var(--color-background-plain-text);
  text-align: center;
  bottom: 0;
  inset-inline-start: 50%;
  top: 50%;
  display: block;
  transform: translateX(-50%);
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: -0.5px;
}
body[dir=rtl] .app-menu-entry__label[data-v-20d8ad9e] {
  transform: translateX(50%) !important;
}
.app-menu-entry__icon[data-v-20d8ad9e] {
  font-size: var(--app-menu-entry-font-size);
}
.app-menu-entry--active .app-menu-entry__label[data-v-20d8ad9e] {
  font-weight: bolder;
}
.app-menu-entry--active[data-v-20d8ad9e]::before {
  content: " ";
  position: absolute;
  pointer-events: none;
  border-bottom-color: var(--color-main-background);
  transform: translateX(-50%);
  width: 10px;
  height: 5px;
  border-radius: 3px;
  background-color: var(--color-background-plain-text);
  inset-inline-start: 50%;
  bottom: 8px;
  display: block;
  transition: all var(--animation-quick) ease-in-out;
  opacity: 1;
}
body[dir=rtl] .app-menu-entry--active[data-v-20d8ad9e]::before {
  transform: translateX(50%) !important;
}
.app-menu-entry__icon[data-v-20d8ad9e], .app-menu-entry__label[data-v-20d8ad9e] {
  transition: all var(--animation-quick) ease-in-out;
}
.app-menu-entry:hover .app-menu-entry__label[data-v-20d8ad9e], .app-menu-entry:focus-within .app-menu-entry__label[data-v-20d8ad9e] {
  font-weight: bold;
}
.app-menu-entry--truncated:hover .app-menu-entry__label[data-v-20d8ad9e], .app-menu-entry--truncated:focus-within .app-menu-entry__label[data-v-20d8ad9e] {
  max-width: calc(var(--header-height) + var(--app-menu-entry-growth));
}
.app-menu-entry--truncated:hover + .app-menu-entry .app-menu-entry__label[data-v-20d8ad9e], .app-menu-entry--truncated:focus-within + .app-menu-entry .app-menu-entry__label[data-v-20d8ad9e] {
  font-weight: normal;
  max-width: calc(var(--header-height) - var(--app-menu-entry-growth));
}
.app-menu-entry:has(+ .app-menu-entry--truncated:hover) .app-menu-entry__label[data-v-20d8ad9e], .app-menu-entry:has(+ .app-menu-entry--truncated:focus-within) .app-menu-entry__label[data-v-20d8ad9e] {
  font-weight: normal;
  max-width: calc(var(--header-height) - var(--app-menu-entry-growth));
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
