import NcActionLink from '@nextcloud/vue/components/NcActionLink';
import NcActionText from '@nextcloud/vue/components/NcActionText';
import NcActionButton from '@nextcloud/vue/components/NcActionButton';
import NcActions from '@nextcloud/vue/components/NcActions';
import NcAvatar from '@nextcloud/vue/components/NcAvatar';
import NcIconSvgWrapper from '@nextcloud/vue/components/NcIconSvgWrapper';
import { getEnabledContactsMenuActions } from '@nextcloud/vue/functions/contactsMenu';
export default {
  name: 'Contact',
  components: {
    NcActionLink,
    NcActionText,
    NcActionButton,
    NcActions,
    NcAvatar,
    NcIconSvgWrapper
  },
  props: {
    contact: {
      required: true,
      type: Object
    }
  },
  computed: {
    actions() {
      if (this.contact.topAction) {
        return [this.contact.topAction, ...this.contact.actions];
      }
      return this.contact.actions;
    },
    jsActions() {
      return getEnabledContactsMenuActions(this.contact);
    },
    preloadedUserStatus() {
      if (this.contact.status) {
        return {
          status: this.contact.status,
          message: this.contact.statusMessage,
          icon: this.contact.statusIcon
        };
      }
      return undefined;
    }
  }
};