import { getCurrentUser } from '@nextcloud/auth';
import { emit, subscribe } from '@nextcloud/event-bus';
import { loadState } from '@nextcloud/initial-state';
import { t } from '@nextcloud/l10n';
import { generateOcsUrl } from '@nextcloud/router';
import { getCapabilities } from '@nextcloud/capabilities';
import { defineComponent } from 'vue';
import { getAllStatusOptions } from "../../../apps/user_status/src/services/statusOptionsService.js";
import axios from '@nextcloud/axios';
import logger from "../logger.js";
import NcAvatar from '@nextcloud/vue/components/NcAvatar';
import NcHeaderMenu from '@nextcloud/vue/components/NcHeaderMenu';
import AccountMenuProfileEntry from "../components/AccountMenu/AccountMenuProfileEntry.vue";
import AccountMenuEntry from "../components/AccountMenu/AccountMenuEntry.vue";
const USER_DEFINABLE_STATUSES = getAllStatusOptions();
export default defineComponent({
  name: 'AccountMenu',
  components: {
    AccountMenuEntry,
    AccountMenuProfileEntry,
    NcAvatar,
    NcHeaderMenu
  },
  setup() {
    const settingsNavEntries = loadState('core', 'settingsNavEntries', {});
    const {
      profile: profileEntry,
      ...otherEntries
    } = settingsNavEntries;
    return {
      currentDisplayName: getCurrentUser()?.displayName ?? getCurrentUser().uid,
      currentUserId: getCurrentUser().uid,
      profileEntry,
      otherEntries,
      t
    };
  },
  data() {
    return {
      showUserStatus: false,
      userStatus: {
        status: null,
        icon: null,
        message: null
      }
    };
  },
  computed: {
    translatedUserStatus() {
      return {
        ...this.userStatus,
        status: this.translateStatus(this.userStatus.status)
      };
    },
    avatarDescription() {
      const description = [t('core', 'Avatar of {displayName}', {
        displayName: this.currentDisplayName
      }), ...Object.values(this.translatedUserStatus).filter(Boolean)].join(' — ');
      return description;
    }
  },
  async created() {
    if (!getCapabilities()?.user_status?.enabled) {
      return;
    }
    const url = generateOcsUrl('/apps/user_status/api/v1/user_status');
    try {
      const response = await axios.get(url);
      const {
        status,
        icon,
        message
      } = response.data.ocs.data;
      this.userStatus = {
        status,
        icon,
        message
      };
    } catch (e) {
      logger.error('Failed to load user status');
    }
    this.showUserStatus = true;
  },
  mounted() {
    subscribe('user_status:status.updated', this.handleUserStatusUpdated);
    emit('core:user-menu:mounted');
  },
  methods: {
    handleUserStatusUpdated(state) {
      if (this.currentUserId === state.userId) {
        this.userStatus = {
          status: state.status,
          icon: state.icon,
          message: state.message
        };
      }
    },
    translateStatus(status) {
      const statusMap = Object.fromEntries(USER_DEFINABLE_STATUSES.map(_ref => {
        let {
          type,
          label
        } = _ref;
        return [type, label];
      }));
      if (statusMap[status]) {
        return statusMap[status];
      }
      return status;
    }
  }
});