// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact[data-v-6999372f] {
  display: flex;
  position: relative;
  align-items: center;
  padding: 3px;
  padding-inline-start: 10px;
  /* actions menu */
}
.contact__action__icon[data-v-6999372f] {
  width: 20px;
  height: 20px;
  padding: 12px;
  filter: var(--background-invert-if-dark);
}
.contact__action__icon-svg[data-v-6999372f] {
  padding: 5px;
}
.contact__avatar[data-v-6999372f] {
  display: inherit;
}
.contact__body[data-v-6999372f] {
  flex-grow: 1;
  padding-inline-start: 10px;
  margin-inline-start: 10px;
  min-width: 0;
}
.contact__body div[data-v-6999372f] {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  margin: -1px 0;
}
.contact__body div[data-v-6999372f]:first-of-type {
  margin-top: 0;
}
.contact__body div[data-v-6999372f]:last-of-type {
  margin-bottom: 0;
}
.contact__body__last-message[data-v-6999372f], .contact__body__status-message[data-v-6999372f], .contact__body__email-address[data-v-6999372f] {
  color: var(--color-text-maxcontrast);
}
.contact__body[data-v-6999372f]:focus-visible {
  box-shadow: 0 0 0 4px var(--color-main-background) !important;
  outline: 2px solid var(--color-main-text) !important;
}
.contact .other-actions[data-v-6999372f] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.contact .other-actions img[data-v-6999372f] {
  filter: var(--background-invert-if-dark);
}
.contact button.other-actions[data-v-6999372f] {
  width: 44px;
}
.contact button.other-actions[data-v-6999372f]:focus {
  border-color: transparent;
  box-shadow: 0 0 0 2px var(--color-main-text);
}
.contact button.other-actions[data-v-6999372f]:focus-visible {
  border-radius: var(--border-radius-pill);
}
.contact .menu[data-v-6999372f] {
  top: 47px;
  margin-inline-end: 13px;
}
.contact .popovermenu[data-v-6999372f]::after {
  inset-inline-end: 2px;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
